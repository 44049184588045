import React from 'react'
import ViewPage from '../../components/ViewPage'
import {getDomain} from "../../functions/functions";

const ClientTaskView = (props) => {

    const DownloadClientShowFile = () => {
        const url = `${getDomain()}/api/client_show/get_excel`
        return (
            <div className={'client-show-download-show-graphic-button'}>
                <a href={url} className={'btn'}>Скачать график показа участков</a>
            </div>
        )
    }

    return (
        <ViewPage {...props} addition_components={<DownloadClientShowFile/>}/>
    )
}

export default ClientTaskView